import React from 'react';
import styled from "styled-components"
import { Button, Card, CardContent } from "@material-ui/core"
import globalhitssImg from '../img/globalhitss.png';
import { Link } from "gatsby-theme-material-ui"
import { useLocation } from '@reach/router';

const FormEncuestaMensaje = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    
    let nombre_encuestado = searchParams.get('nombre_encuestado');
    const id_encuesta = searchParams.get('id_encuesta');

    nombre_encuestado = capitalizeFirstLetter(nombre_encuestado);

    function capitalizeFirstLetter(words) {
	if (words === null) { return ''; };
        return words.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    }
    
    return (
        <Styled>
            <div className="ContactForm">
                <Card style={{ width: '800px', padding: '30px', margin: '50px auto' }}>
                    <CardContent>
                        <div style={{ textAlign: 'center' }}>
                            <img src={globalhitssImg} alt="Icono Global Hitss" width="320" height="180" style={{marginTop: '-60px'}}/>
                            <p style={{fontSize: '25px', marginTop: '-50px', fontWeight: 'bold'}}>{nombre_encuestado}</p>
                            <p style={{fontSize: '15px'}}>
                                Desde <b>Global Hitss Argentina</b> queremos agradecer <br />
                                sinceramente su confianza en nuestros servicios y <br />
                                productos. Por eso, nos gustaría conocer más de su <br />
                                experiencia con nosotros a través de esta breve <br />
                                encuesta para seguir mejorando hy brindar la mejor <br />
                                experiencia posible a nuestros clientes.
                            </p>
                            <p style={{marginTop: '10px'}}>Atte, Alberto Morales, PO de Comercio</p>
                        </div>
                        <Link to={`/encuesta-de-satisfaccion/encuesta-preguntas?id_encuesta=${id_encuesta}`} style={{display: 'flex', justifyContent: 'center'}}>
                            <Button
                                type="button"
                                variant="contained"
                                className="submit"
                                style={{ backgroundColor: '#269094', color: '#fff',  width: '60%'}}
                            >
                                Responder Encuesta
                            </Button>
                        </Link>
                    </CardContent>
                </Card>
            </div>
        </Styled>
    )
}

const Styled = styled.div`
 .title {
    width: 100%;
    padding: '0px 70px';
    color: #000000;
    font-family: Questrial;
    font-weight: 500;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 32px;
    text-align: center;
    margin: 0;

    
    @media (max-width: 780px) {
        padding: 0px 10px;
    }
 }

 .linkAccess{
    margin: 0 auto;
    height: 67px;
    max-width: 500px;
    border-radius: 33.5px;
    background-color: #24CC65;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;

    .icon {
        width: 70px;
        margin-left: 5px;
        svg {
            height: 40px;
            width: 71.11px;
        }
    }

    .desc {

        padding-right: 15px;

        p {
            color: #FFFFFF;
            font-family: "Century Gothic";
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 17px;
            text-align: center;
            margin: 0;
            text-decoration: none;

            a {
                color: white !important;
            }
        }
    }
 }

 .formControl{
     min-height: 65px;     

     .form-field:after {
        border-bottom-color: #f44336 !important;
    }
 }
 .submit{
    color: #FFFFFF;
    font-family: "Century Gothic";
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
    margin-top: 32px;
    height: 40px;
    background-color: #A5BE01;
 }

 .form {
    margin-bottom: 0;
 }
`

export default FormEncuestaMensaje;
